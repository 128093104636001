// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-compare-presidents-index-js": () => import("./../../../src/pages/compare-presidents/index.js" /* webpackChunkName: "component---src-pages-compare-presidents-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-center-index-js": () => import("./../../../src/pages/learning-center/index.js" /* webpackChunkName: "component---src-pages-learning-center-index-js" */),
  "component---src-pages-metrics-gdp-growth-js": () => import("./../../../src/pages/metrics/gdp-growth.js" /* webpackChunkName: "component---src-pages-metrics-gdp-growth-js" */),
  "component---src-pages-metrics-index-js": () => import("./../../../src/pages/metrics/index.js" /* webpackChunkName: "component---src-pages-metrics-index-js" */),
  "component---src-pages-metrics-jobs-growth-js": () => import("./../../../src/pages/metrics/jobs-growth.js" /* webpackChunkName: "component---src-pages-metrics-jobs-growth-js" */),
  "component---src-pages-metrics-stock-market-performance-js": () => import("./../../../src/pages/metrics/stock-market-performance.js" /* webpackChunkName: "component---src-pages-metrics-stock-market-performance-js" */),
  "component---src-pages-presidents-index-js": () => import("./../../../src/pages/presidents/index.js" /* webpackChunkName: "component---src-pages-presidents-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-templates-comparison-js": () => import("./../../../src/templates/comparison.js" /* webpackChunkName: "component---src-templates-comparison-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-president-js": () => import("./../../../src/templates/president.js" /* webpackChunkName: "component---src-templates-president-js" */)
}

